export enum ModalName {
	addCodeAssets = "addCodeAssets",
	deleteConnection = "deleteConnection",
	deleteDeployment = "deleteDeployment",
	deleteDeploymentSession = "deleteDeploymentSession",
	deleteFile = "deleteFile",
	deleteAccount = "deleteAccount",
	deleteMemberFromOrg = "deleteMemberFromOrg",
	deleteTrigger = "deleteTrigger",
	deleteVariable = "deleteVariable",
	getToken = "getToken",
	deleteProject = "deleteProject",
	deleteWithActiveDeploymentProject = "deleteWithActiveDeploymentProject",
	deleteWithDrainingDeploymentProject = "deleteWithDrainingDeploymentProject",
	welcomePage = "welcomePage",
	templateCreateProject = "templateCreateProject",
	newProject = "newProject",
	importProject = "importProject",
	warningDeploymentActive = "warningDeploymentActive",
	organizationCreated = "organizationCreated",
	organizationMemberCreate = "organizationMemberCreate",
	deleteOrganization = "deleteOrganization",
	invitedUser = "invitedUser",
}
